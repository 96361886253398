<template>
  <div class="menu w-72 h-screen relative">
    <div class="fixed w-72 h-screen">
      <div
        class="bg-nColorTextInput min-h-[calc(100vh-15px)] p-8 m-2 rounded-2xl relative"
      >
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            alt="Playda Logo"
            width="130"
            height="56"
            @click="goToDashboard()"
          />
        </div>
        <div class="grid gap-4 mt-4" v-if="step != 'step4'">
          <div
            v-for="(step, index) in wizard.steps"
            :key="index"
            class="cursor-pointer"
            @click.once.prevent="navigateToStep(step)"
          >
            <div class="flex gap-2 items-center my-2">
              <span
                class="w-5 h-5 rounded-full bg-nColorOrange text-xs font-InterBold flex justify-center items-center text-black"
                v-if="isCurrentStep(step.slug)"
                >{{ index + 1 }}</span
              ><span
                class="w-5 h-5 rounded-full bg-nColorGreen text-xs font-InterBold flex justify-center items-center text-black"
                v-else-if="isStepComplete(step.slug)"
                >&#10003;</span
              ><span
                v-else
                class="w-5 h-5 rounded-full bg-nColorLightWhite text-xs font-InterBold flex justify-center items-center text-black"
                >{{ index + 1 }}</span
              >
              <p class="font-InterSemiBold text-sm text-nColorYellow">
                {{ step.text }}
              </p>
            </div>
          </div>
        </div>
        <div
          :class="{
            'mt-96': step == 'step4',
          }"
          class="grid gap-2 mt-32"
        >
          <p class="font-InterMedium text-nColorLightGray text-sm">
            Can't find something you're looking for?
          </p>
          <router-link
            :to="{
              name: 'ContactUs',
            }"
            class="w-5/6 text-center font-ZuumeMedium border border-nColorYellow text-nColorYellow px-6 py-1 rounded-3xl text-2xl"
          >
            Contact Us
          </router-link>
        </div>
        <div class="absolute bottom-8 w-9/12">
          <div class="grid gap-1">
            <div class="grid gap-1">
              <p class="text-nColorLightGray text-sm">
                Buy a plan & Create more Game Jams!
              </p>
              <button
                class="w-5/6 font-ZuumeMedium text-nColorButtonText bg-nColorYellow px-6 py-1 rounded-3xl mt-2 text-2xl"
                @click.prevent="goToPricing()"
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wizard from "@/common/wizard.config";

export default {
  components: {},
  name: "DesktopMenu",
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      wizard,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    // goToStep(step, slug) {
    //   if (this.isStepComplete(slug)) {
    //     this.$router.push({
    //       name: "CreateGameJam",
    //       params: {
    //         game_jam_id: this.$route.params.game_jam_id,
    //         step: "step" + step,
    //       },
    //     });
    //   }
    // },
    isStepComplete(slug) {
      if (
        Object.hasOwnProperty.call(this.gameJamData, slug) &&
        this.gameJamData[slug] !== null
      ) {
        return true;
      }

      return false;
    },
    isCurrentStep(slug) {
      if (this.$route.params.step === slug) {
        return true;
      }
      return false;
    },
    navigateToStep(step) {
      if (this.isStepComplete(step.slug)) {
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: this.$route.params.game_jam_id,
            step: step.slug,
          },
        });
      }
    },
    goToPricing() {
      this.$router.push({
        name: "Pricing",
      });
    },
  },
};
</script>

<style scoped>
.menu {
  height: 100dvh;
}
.top-half-border {
  border-image: linear-gradient(to bottom, transparent 50%, #f67134 50%) 0 1;
}
.bottom-half-border {
  border-image: linear-gradient(to bottom, #f67134 50%, transparent 50%) 0 1;
}
</style>
